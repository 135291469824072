* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #242a24; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

.nav-link:hover,
.dropdown-item:hover {
  text-decoration: underline;
  text-underline-offset: 7px;
  text-decoration-color: rgba(134, 5, 208, 0.758);
  transform: translateY(-2px);
}

.image {
  width: 150px;
  margin: 0 10px;
}

.form-control {
  width: 20vw;
  border: none;
  border: 2px solid lightgray;
  background-color: rgba(248, 249, 250, 1);
}

.btn {
  width: 100px;
}

::placeholder {
  padding-left: 5px;
  font-size: 16px;
}

.card {
  transition: .8s;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 5px 10px -5px rgb(0, 0, 0);
  transform: scale(1.1);
}

.footer {
  position: static;
  background: none;
  bottom: 0;
  padding: 10px;
  /* background-color: #3B4331; */
  background-color: rgba(0, 0, 0, 0.7);
  margin-top: 20px;
  padding: 10px;
  color: #fff;
}

.itmes {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20vw;
  margin: auto;
}

.logo img {
  height: 5vw;
  cursor: pointer;
}

.logo .content {
  display: flex;
}

.item {
  margin: auto;
  width: 10vw;
  text-align: center;
}

.item h3 {
  font-weight: bolder;
}

.item ul {
  padding: 0;
  margin: 0;
}

.item ul li {
  list-style: none;
  padding: 5px 0;
  font-weight: 500;
}

.last {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: white;
  padding-left: 50px;
}

a {
  padding: 10px;
}

.whatsapp {
  position: fixed;
  left: 20px;
  bottom: 20px;
  background-color: #25d366;
  width: 4vw;
  border-radius: 50%;
  text-align: center;
}

.top {
  position: fixed;
  right: 20px;
  text-align: end;
  color: black;
  bottom: 20px;
  text-decoration: none;
  background-color: #4e5544;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1450px) {
  .whatsapp {
    display: flex;
    justify-content: center;
  }

  .footer {
    font-size: 12px;
  }
  
  .footer .h3 {
    font-size: 15px;
  }

  .last {
    padding-left: 0px;
  }
}